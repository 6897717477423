<template>
  <div class="relative h-full">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="w-full max-w-4xl px-4">
        <card class="mx-auto py-12">
          <div class="mx-auto flex h-full max-w-2xl flex-col justify-center">
            <div class="mb-4 flex flex-wrap justify-start px-8">
              <div class="w-full pb-8">
                <div class="flex -space-x-1">
                  <img
                    v-for="imageName in imageNames"
                    :key="imageName"
                    class="h-6 w-auto"
                    alt="firstvet logo"
                    :src="require(`@/assets/svg/flags/${imageName}.svg`)"
                  />
                </div>
              </div>
              <img
                :src="require('@/assets/svg/firstvet_logo.svg')"
                class="mx-auto h-12 w-auto"
              />
            </div>

            <component
              :is="loginComponent"
              :legacy-login="legacyLogin"
              :clinic-slug="clinicSlug"
              :error-msg="errorMsg"
            />
          </div>
          <div class="flex w-full flex-col items-center justify-center">
            <a
              id="changePlatform"
              :href="loginUrl"
              class="flex items-center text-sm text-gray-700 hover:text-gray-900"
            >
              {{ loginTitle }}
              <fv-icon icon="chevron-right" size="xs" />
            </a>
            <button
              class="flex items-center text-sm text-gray-700 hover:text-gray-900"
              data-testid="change-login"
              @click="legacyLogin = !legacyLogin"
            >
              {{ legacyLogin ? 'Use new login' : 'Use old login' }}
              <fv-icon icon="chevron-right" size="xs" />
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginIntl from '@/components/auth/LoginIntl';
import LoginSv from '@/components/auth/LoginSv';
import DigitalClinicApi from '@/api/modules/admin/digital-clinic';
import {
  BASE_URL,
  TALK_APP_ID,
  FIRSTVET_TOKEN_COOKIE_DOMAIN,
} from '@/constants';
import { getCookie, setCookie } from '@/utils/cookie-utils';
import { mapActions } from 'vuex';

export default {
  components: {
    LoginIntl,
    LoginSv,
  },
  data() {
    return {
      name: '',
      BASE_URL,
      TALK_APP_ID,
      legacyLogin: false,
      errorMsg: '',
    };
  },
  computed: {
    imageNames() {
      const isSweden = this.currentPlatform === 'sv';
      if (isSweden) {
        return ['sv'];
      }
      return ['uk', 'en', 'no', 'fi', 'dk', 'de'];
    },
    currentPlatform() {
      return this.$router.currentRoute.params.platform;
    },
    loginUrl() {
      return this.currentPlatform === 'sv' ? BASE_URL : `${BASE_URL}/sv`;
    },
    loginTitle() {
      return this.currentPlatform === 'sv'
        ? 'Go to International Vetpanel Login'
        : 'Go to Swedish Vetpanel Login';
    },
    loginComponent() {
      return this.currentPlatform === 'sv' ? 'LoginSv' : 'LoginIntl';
    },
    clinicSlug() {
      return this.$router.currentRoute.query.clinic || false;
    },
    logoUrl() {
      return this.logo
        ? process.env.VUE_APP_DIGITAL_CLINIC_IMAGE_BASE_URL + this.logo
        : '';
    },
  },
  beforeCreate() {
    localStorage.setItem(
      'platform',
      this.$router.currentRoute.params.platform || 'intl'
    );
  },
  async mounted() {
    if (this.clinicSlug) {
      this.getClinic(this.clinicSlug);
    }

    if (getCookie('firstvetToken')) {
      localStorage.token = getCookie('firstvetToken');
      try {
        await this.setAuthData(localStorage.token);
        this.handleLoginRoutePush();
      } catch (error) {
        this.errorMsg = error.message;
        setCookie({
          name: 'firstvetToken',
          value: '',
          domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
          maxAge: 0,
        });
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthData', 'handleLoginRoutePush']),

    getClinic(slug) {
      return DigitalClinicApi.fetchClinic(slug)
        .then(res => {
          if (res.data.logo) {
            this.logo = res.data.logo;
          } else {
            this.logoError = true;
            this.name = res.data.name;
          }
        })
        .catch(() => {
          this.logoError = true;
        });
    },
  },
};
</script>
