import { getTimeZones } from '@vvo/tzdb';

const getTimeZoneName = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const getTimezonesForCountry = country =>
  getTimeZones()
    .filter(timeZone => timeZone.countryName === country)
    .map(timeZone => timeZone.name);

export { getTimeZoneName, getTimezonesForCountry };
