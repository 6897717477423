/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
import Vue from 'vue';
import Vuex from 'vuex';

import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
// API
import { VersionApi } from '@/api';

// Modules
import { SV_COUNTRY_ID, UK_COUNTRY_ID } from '@/config/countries';
import roomTabs from '@/config/room-tabs';
import { getDefaultApi } from '@/utils/http-utils';
import adminStore from './modules/admin';
import firebaseStore from './modules/firebase';
import appointmentStore from './modules/appointment-store';
import animalStore from './modules/animal-store';
import authStore from './modules/auth-store';
import clinicStore from './modules/map/clinic-store';
import clinicMapStore from './modules/map/clinic-map-store';
import otStore from './modules/opentok-store';
import journalStore from './modules/journal-store';
import journalTemplatesStore from './modules/journal-templates-store';
import insuranceCompanyStore from './modules/insurance-companies-store';
import remoteBookingStore from './modules/remote-booking-store';
import userStore from './modules/user-store';
import questionsStore from './modules/question-store';
import supportStore from './modules/support-store';
import referralStore from './modules/referral-store';
import countryStore from './modules/country-store';
import axiosInstance from '../plugins/axios';

// Helpers

const logrocketPlugin = createPlugin(LogRocket, mutation => {
  if (mutation.type === 'auth/setToken') {
    return {
      ...mutation,
      payload: 'SECRET',
    };
  }

  return mutation;
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [logrocketPlugin],

  modules: {
    admin: {
      namespaced: true,
      ...adminStore,
    },

    firebase: {
      namespaced: true,
      ...firebaseStore,
    },

    auth: {
      namespaced: true,
      ...authStore,
    },

    animal: {
      namespaced: true,
      ...animalStore,
    },

    appointment: {
      namespaced: true,
      ...appointmentStore,
    },

    clinicStore: {
      namespaced: true,
      ...clinicStore,
    },

    clinicMap: {
      namespaced: true,
      ...clinicMapStore,
    },

    insurance: {
      namespaced: true,
      ...insuranceCompanyStore,
    },

    opentok: {
      namespaced: true,
      ...otStore,
    },

    question: {
      namespaced: true,
      ...questionsStore,
    },

    journal: {
      namespaced: true,
      ...journalStore,
    },

    templates: {
      namespaced: true,
      ...journalTemplatesStore,
    },

    user: {
      namespaced: true,
      ...userStore,
    },

    support: {
      namespaced: true,
      ...supportStore,
    },
    referral: {
      namespaced: true,
      ...referralStore,
    },
    country: {
      namespaced: true,
      ...countryStore,
    },
    remoteBooking: {
      namespaced: true,
      ...remoteBookingStore,
    },
  },

  state: {
    tab: {
      title: '',
      tab: '',
      icon: 'info-circle',
    },

    showTimeline: true,

    showSupportTicket: false,
    locale: 'uk',
    platform: 'intl',
    appVersion: '',
    hasLatestVersion: true,
  },

  getters: {
    getTab: state => state.tab,
    getLocale: state => state.locale,
    getPlatform: state => state.platform,
  },

  mutations: {
    setTab: (state, payload) => {
      state.tab = payload;
    },
    setInitialTab: state => {
      [state.tab] = roomTabs;
    },
    setTimeLineOpen: (state, payload) => (state.showTimeline = payload),
    toggleTimeline: state => (state.showTimeline = !state.showTimeline),
    toggleSupportTicketModal: (state, payload) =>
      (state.showSupportTicket = payload),
    setPlatform: (state, platform) => {
      state.platform = platform;
    },
    setAppVersion(state, ver) {
      state.appVersion = ver;
    },
    setHasLatestVersion(state, bool) {
      state.hasLatestVersion = bool;
    },
  },

  actions: {
    /**
     * @param {string} platform
     */
    setPlatform({ commit }, platform) {
      commit('setPlatform', platform);
      if (platform === 'sv') {
        localStorage.setItem('selectedCountry', SV_COUNTRY_ID);
      } else if (
        localStorage.getItem('selectedCountry') === SV_COUNTRY_ID ||
        !localStorage.getItem('selectedCountry')
      ) {
        localStorage.setItem('selectedCountry', UK_COUNTRY_ID);
      }
      commit('admin/setCountry', localStorage.getItem('selectedCountry'));
      axios.defaults.baseURL = getDefaultApi();
    },
    sendSupportTicket(_, payload) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(payload);
        }, 1000);
      });
    },
    async compareAppVersions({ state }) {
      const version = await VersionApi.getLatestVersion();
      const hasLatestVersion = version === state.appVersion;
      this.commit('setHasLatestVersion', hasLatestVersion);
    },
    async fetchVersion({ commit }) {
      const version = await VersionApi.getLatestVersion();
      localStorage.setItem('appVersion', version);
      axiosInstance.defaults.headers.common['App-Version'] = version;
      commit('setAppVersion', version);
    },
  },
});
