import AuthApi from '@/api/modules/auth';
import CountrySettingsApi from '@/api/modules/admin/country-settings';
import DigitalClinicsApi from '@/api/modules/admin/digital-clinic';
import AnimalApi from '@/api/modules/animal';
import Firebase from '@/api/modules/firebase';
import AppointmentApi from '@/api/modules/appointment';
import DiagnosisCodesApi from '@/api/modules/diagnosis-codes';
import InsuranceCompanyApi from '@/api/modules/insurance-company';
import JournalApi from '@/api/modules/journal';
import JournalTemplatesApi from '@/api/modules/journal-templates';
import OrdersApi from '@/api/modules/orders';
import QuestionsApi from '@/api/modules/questions';
import UserApi from '@/api/modules/user';
import VetStatsApi from '@/api/modules/vet-stats';
import QuinyxApi from '@/api/modules/admin/quinyx';
import ScheduleApi from '@/api/modules/admin/schedule';
import VetsApi from '@/api/modules/admin/vets';
import AnimalsApi from '@/api/modules/admin/animals';
import SupportApi from '@/api/modules/support';
import VersionApi from '@/api/modules/version';
import AppointmentUploadsApi from '@/api/modules/appointment-uploads';
import ClinicListingApi from '@/api/modules/clinic-listing';
import ReferralApi from '@/api/modules/referral';
import EcomApi from '@/api/modules/ecom';
import ChatApi from '@/api/modules/chat';
import CountryApi from '@/api/modules/country';
import AdminAppointmentApi from '@/api/modules/admin/appointments';
import ClinicsApi from '@/api/modules/clinics';
import RatingApi from '@/api/modules/ratings';
import RemoteBookingApi from '@/api/modules/remote-booking';
import JobTitleApi from '@/api/modules/job-title';
import PartnerApi from '@/api/modules/partners';

export {
  AnimalApi,
  AppointmentApi,
  AdminAppointmentApi,
  AuthApi,
  ClinicsApi,
  ClinicListingApi,
  DiagnosisCodesApi,
  Firebase,
  InsuranceCompanyApi,
  JournalApi,
  JournalTemplatesApi,
  OrdersApi,
  QuestionsApi,
  UserApi,
  VetStatsApi,
  CountrySettingsApi,
  DigitalClinicsApi,
  QuinyxApi,
  ScheduleApi,
  VetsApi,
  AnimalsApi,
  SupportApi,
  VersionApi,
  AppointmentUploadsApi,
  ReferralApi,
  EcomApi,
  ChatApi,
  CountryApi,
  RatingApi,
  RemoteBookingApi,
  JobTitleApi,
  PartnerApi,
};
