// eslint-disable-next-line import/no-cycle
import store from '@/store';

const guards = {
  async requireAuth(to, from, next) {
    const isOnSvPlatform = localStorage.getItem('platform') === 'sv';
    if (to.matched.some(record => record.meta.auth)) {
      // No token is set, redirect to root page (login)
      if (localStorage.getItem('token') == null) {
        if (isOnSvPlatform) {
          next({ path: '/sv' });
        }
        next({
          path: '/',
          params: { nextUrl: to.fullPath },
        });
      } else {
        // Token is set, presume valid auth
        store.commit('auth/setAuth', true);

        // If current route is an appointment, and the call is active
        // ask if they are sure about leaving the page.
        if (
          from.matched.some(record => record.name === 'appointment') &&
          store.getters['opentok/getActiveOrActivating']
        ) {
          // eslint-disable-next-line no-alert
          const sureLeave = window.confirm(
            'You still have an active video connection. Sure you want to disconnect and leave?'
          );
          if (sureLeave) {
            next();
          }
        } else {
          // If token is set, but no user is found in state, fetch the user.
          if (!store.getters['user/getUser']) {
            await store.dispatch('user/fetchUser');
          }

          const isAdmin = store.getters['auth/isAdmin'];
          const isDigitalClinicAdmin =
            store.getters['auth/isDigitalClinicAdmin'];
          const hasSelectedDigitalClinic =
            store.getters['admin/digital-clinic/getDigitalClinicId'];

          if (
            to.matched.some(record => record.meta.digitalClinicOnly) &&
            !hasSelectedDigitalClinic &&
            isAdmin
          ) {
            next({ name: 'admin' });
          } else if (
            to.matched.some(record => record.meta.digitalClinicAdmin)
          ) {
            if (isDigitalClinicAdmin || isAdmin) {
              next();
            } else {
              next({ name: 'dashboard' });
            }
          } else if (to.matched.some(record => record.meta.admin)) {
            if (isAdmin) {
              next();
            } else {
              next({ name: 'dashboard' });
            }
          } else {
            next();
          }
        }
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (localStorage.getItem('token') == null) {
        next();
      } else {
        if (isOnSvPlatform) {
          next('/vetpanel/admin/schedule');
        }
        next({ name: 'dashboard' });
      }
    } else {
      next();
    }
  },
};

export default guards;
