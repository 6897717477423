import { __PROD__ } from '@/constants';
import { VetStatsApi, UserApi } from '@/api';
import { identifyUser as identifyUserForSentry } from '@/plugins/sentry';
import identifyUser from '@/plugins/logrocket';
import {
  userInitializedEvent,
  userDetailsInitializedEvent,
} from '@/plugins/analytics/user';

export default {
  state: {
    user: null,
    stats: null,
    services: [],
    animalTypes: [],
    languages: [],
    roles: [],
    country: null,
    testScheduleAvailable: ['development', 'staging'].includes(
      process.env.VUE_APP_ENV
    ),
  },

  getters: {
    getUser: state => {
      return state.user;
    },
    getUserId: state => {
      return state.user?.id;
    },
  },

  mutations: {
    setUser: (state, payload) => {
      state.user = payload;
    },
    setStats: (state, payload) => {
      state.stats = payload;
    },
    setRoles: (state, payload) => {
      state.roles = payload;
    },
    setAnimalTypes: (state, payload) => {
      state.animalTypes = payload;
    },
    setServices: (state, payload) => {
      state.services = payload;
    },
    setLanguages: (state, payload) => {
      state.languages = payload;
    },
    setCountry: (state, payload) => {
      const country = { ...payload };
      if (payload?.timezone) country.timeZone = payload.timezone;
      state.country = country;
    },
  },

  actions: {
    async fetchUser({ commit, state, dispatch }) {
      if (state.user !== null) return false;

      try {
        const { data } = await UserApi.fetchUser();

        const { user, roles, isDigitalClinicAdmin, claims, country } = data;

        commit('setUser', user);

        commit('setCountry', country);

        const abilities = { user, ...claims };
        commit('auth/setPermissions', abilities, { root: true });

        commit('auth/setRoles', roles, {
          root: true,
        });
        commit('auth/setIsDigitalClinicAdmin', isDigitalClinicAdmin, {
          root: true,
        });
        if (__PROD__) {
          identifyUser(user);
          identifyUserForSentry(user);
        }

        userInitializedEvent(data);
        dispatch('fetchUserDetails');

        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchStats({ commit }) {
      try {
        const res = await VetStatsApi.getStats();
        commit('setStats', res.data);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchUserDetails({ commit, state }) {
      try {
        const { data } = await UserApi.fetchUserDetails(state.user.id);
        commit('setRoles', data.roles);
        commit('setAnimalTypes', data.animalTypes);
        commit('setServices', data.services);
        commit('setLanguages', data.languages);
        userDetailsInitializedEvent(data);
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async generateTestSchedule({ state }, payload) {
      try {
        const res = await UserApi.createTestSchedule(state.user.id, payload);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
