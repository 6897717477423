/* eslint-disable no-underscore-dangle */

const __PROD__: boolean | undefined = process.env.NODE_ENV === 'production';

const __STAGING__: boolean | undefined = process.env.NODE_ENV === 'staging';

const __DEV__: boolean | undefined = !__PROD__ && !__STAGING__;

const API_URL_INTL: string | undefined = process.env.VUE_APP_API_URL_INTL;

const API_URL_SV: string | undefined = process.env.VUE_APP_API_URL_SV;

const VETPANEL_SV_URL = `${API_URL_SV}/vetpanel/`;

const SENTRY_URL: string | undefined = process.env.VUE_APP_SENTRY_URL;

const BASE_URL: string | undefined = process.env.VUE_APP_BASE_URL;

const TALK_APP_ID: string | undefined = process.env.VUE_APP_TALK_APP_ID;

const FIRSTVET_AUTH_URL: string | undefined =
  process.env.VUE_APP_FIRSTVET_AUTH_URL;

const FIRSTVET_TOKEN_COOKIE_DOMAIN: string | undefined =
  process.env.VUE_APP_FIRSTVET_TOKEN_COOKIE_DOMAIN;

export {
  __PROD__,
  __STAGING__,
  __DEV__,
  API_URL_INTL,
  API_URL_SV,
  SENTRY_URL,
  BASE_URL,
  VETPANEL_SV_URL,
  TALK_APP_ID,
  FIRSTVET_AUTH_URL,
  FIRSTVET_TOKEN_COOKIE_DOMAIN,
};
