export default [
  {
    path: '',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'appointment/:id',
    name: 'appointment',
    component: () => import(/* webpackChunkName: "room" */ '@/views/Room.vue'),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: 'chat-room',
  //   name: 'chat-room',
  //   component: () =>
  //     import(/* webpackChunkName: "chat-room" */ '@/views/ChatRoom.vue'),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: 'appointments',
    name: 'appointments',
    component: () =>
      import(/* webpackChunkName: "appointments" */ '@/views/Appointments.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'calendar',
    name: 'calendar',
    component: () =>
      import(/* webpackChunkName: "calendar" */ '@/views/Calendar.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'network-diagnosis',
    name: 'network-diagnosis',
    component: () =>
      import(/* webpackChunkName: "networktest" */ '@/views/NetworkTest.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'statistics',
    name: 'statistics',
    component: () =>
      import(/* webpackChunkName: "statistics" */ '@/views/Statistics.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
    meta: {
      auth: true,
    },
  },

  {
    path: 'questions',
    name: 'questions',
    component: () =>
      import(/* webpackChunkName: "questions" */ '@/views/Questions.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'question/:id',
    name: 'question',
    component: () =>
      import(/* webpackChunkName: "question" */ '@/views/Question.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: 'messages',
    name: 'messages',
    component: () =>
      import(/* webpackChunkName: "messages" */ '@/views/Messages.vue'),
    meta: {
      auth: true,
      admin: false,
      title: 'Messages',
    },
  },
  {
    path: 'message/:id',
    name: 'message',
    component: () =>
      import(
        /* webpackChunkName: "message" */ '@/views/admin/SupportTicket.vue'
      ),
    meta: {
      auth: true,
      admin: false,
      title: 'Message',
    },
  },
  {
    path: 'map',
    name: 'map',
    component: () =>
      import(
        /* webpackChunkName: "vet-clinics" */ '@/views/admin/Clinics/ClinicVetMap.vue'
      ),
    meta: {
      auth: true,
      admin: false,
      title: 'Clinic map',
    },
  },
  {
    path: '2288-dashboard',
    name: '2288-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "clinic-listing" */ '@/views/clinic-listing/2288-Dashboard.vue'
      ),
    meta: {
      auth: true,
      admin: false,
      referralCoordinator: true,
      digitalClinicAdmin: false,
      title: '2288 Dashboard',
    },
  },
  {
    path: 'templates',
    name: 'templates',
    component: () =>
      import(
        /* webpackChunkName: "journal-templates" */ '@/views/JournalTemplates.vue'
      ),
    meta: {
      auth: true,
      admin: false,
      title: 'Journal templates',
    },
  },
  {
    path: 'ratings',
    name: 'ratings',
    component: () =>
      import(/* webpackChunkName: "ratings" */ '@/views/Ratings.vue'),
    meta: {
      auth: true,
      admin: false,
      title: 'Ratings',
    },
  },
  {
    path: 'support',
    name: 'support',
    component: () =>
      import(/* webpackChunkName: "vet-clinics" */ '@/views/Support.vue'),
    meta: {
      auth: true,
      admin: false,
      title: 'Support information',
    },
  },
];
