import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { __DEV__, __STAGING__ } from '@/constants';

const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  encrypted: true,
  cluster: 'eu',
  auth: {
    headers: {
      Accept: 'application/json',
    },
  },
});
const isOnSvPlatform = localStorage.getItem('platform') === 'sv';

const apiUrl = isOnSvPlatform
  ? process.env.VUE_APP_API_URL_SV
  : process.env.VUE_APP_API_URL_INTL;
EchoInstance.connector.pusher.config.authEndpoint = `${apiUrl}/broadcasting/auth`;

if (__DEV__ || __STAGING__) {
  Pusher.logToConsole = true;
}
export default EchoInstance;
