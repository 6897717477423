import { API_URL_INTL, API_URL_SV } from '@/constants';

const getPlatform = () => {
  const platform = localStorage.getItem('platform');
  if (!platform) {
    return 'intl';
  }
  return platform;
};

const getDefaultApi = () => {
  return getPlatform() === 'sv' ? API_URL_SV : API_URL_INTL;
};

export { getDefaultApi, getPlatform };
